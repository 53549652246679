// Here you can add other styles
.card-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar-brand {
    background: #fff;
    padding: 0rem 1rem;
}
.sidebar-nav {
    background: #009efb;
}
.sidebar.sidebar-fixed {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

/* Customize scrollbar width for elements with the .custom-element class */
.sidebar-nav::-webkit-scrollbar {
    width: 1px; /* Set the desired width */
  }
  
  /* Customize scrollbar track for elements with the .custom-element class */
  .sidebar-nav::-webkit-scrollbar-track {
    background-color: #009efb; /* Set the desired background color */
  }
  
  /* Customize scrollbar thumb for elements with the .custom-element class */
  .sidebar-nav::-webkit-scrollbar-thumb {
    background-color: #e4e3e8; /* Set the desired thumb color */
  }
  .sidebar-toggler {
    background-color: #009efbd9;
}
html:not([dir=rtl]) .sidebar-nav .nav-icon:first-child {
    color: #fff;
}
.sidebar-nav .nav-link {
    color: rgb(255 255 255);
}
.bg-lightblue{
    background-color: #f2f8fb
}
.form-select.c{
  height: 15vw;
}
.slider_img{
  width: 30vw !important;
}
.slider_img.a{
  width: 15vw !important;
}
.bg-primarycolor{
    background-color: #009efb;
    color: #fff;
}
.header.header-sticky {
    background-color: rgb(0 158 251);
}
.header-nav .show > .nav-link, .header-nav .nav-link.active {
    color: rgb(255 255 255);
}
.header-nav .nav-link {
    color: rgb(255 255 255);
}
button.header-toggler.ps-1 {
    color: #fff;
}
.btn:hover {
    color: var(--cui-btn-hover-color);
    background-color: #009efb;
    border-color: #009efb;
}
.btn-primary {
    color: var(--cui-btn-hover-color);
    background-color: #009efb;
    border-color: #009efb;
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: #fff;
    background-color: #009efb;
    border-color: #009efb;
}
.sidebar-nav .nav-title {
    color: rgb(255 255 255);
}
.sidebar-nav .nav-link:hover {
    border-width: 0 5px 0 0;
    border-color: #fff;
    
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-row {
    margin-bottom: 10px;
  }
  .form-group{
    margin-bottom: 10px;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0069d9;
  }
  
  .form-check-label {
    margin-left: 5px;
  }
  
.form-group label{
    margin-bottom: 10px;
}  
/*************Select option arrow down ****************************/
.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .arrow-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .arrow-icon svg {
    width: 12px;
    height: 12px;
    fill: #000;
  }
  /************radio button css*************************/
  .radio-label {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-text {
    margin-left: 8px;
  }
  /*****************Checkbox css****************************/

  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkbox-text {
    margin-left: 8px;
  }
  
  .border-40 {
    border-radius: 40px;
}
.btn-white {
  background-color: #fff;
  color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  cursor: pointer;
}
.mr-2{
  margin-right: 16px;
}

/***************Swapping box **********************************************/

.portalmapping .container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 10px;
  padding: 0;
}

.portalmapping .container .box {
  width: 200px;
  height: auto;
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
}

.portalmapping .container .box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.portalmapping .container .box li {
  margin-bottom: 10px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.portalmapping .container img {
  margin: auto 20px;
}

.portalmapping .container .box li:hover {
  background-color: #009efb;
  color: #fff;
}

.swipe-icon {
  font-size: 24px;
  margin: 5px;
}

.fade-out {
  opacity: 0;
}

.hello{
  display: flex;
  align-items: center;
}
.hello img{
  border-radius: 50%;
  width: 150px;
  height: auto;
}

.heading-box{
  min-width: 300px;
  padding-left: 4%;
}
.heading-box h1{
    font-size: 2em;
    margin: 0;
    margin-bottom: 5px;
    color: black;
}
.heading-box h3{
    font-size: 1.5em;
    color: lighten(black, 40%);
    font-weight: normal;
    margin: 0;
    margin-top: 2%;
}
.heading-box span{
      color: lighten(black, 60%);
      margin-left: 2%;
}

/*********************order edit ***************************************/
.card-header.order-detail strong{
  font-size: 1.6em;
}
.card-header.order-detail p{
  font-size: 1em;
  font-weight: 500;
  color: #fff !important;
}
/* styles.css */
/* styles.css */
.react-datepicker-wrapper {
  width: 100%;
}

.date-picker-wrapper {
  position: relative;
}

.date-input {
  padding-right: 30px; /* Adjust the padding to accommodate the icon */
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.react-datepicker-wrapper {
  width: 100%;
}

.time-picker-wrapper {
  position: relative;
}

.time-input {
  padding-right: 30px; /* Adjust the padding to accommodate the icon */
}

.clock-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.small-button {
  font-size: 12px;
  padding: 4px 6px;
}


.drag-image-field {
  width: 100%;
  height: 30vh;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: center;
}

.drag-image-field.dragging {
  border-color: #007bff;
}

.drag-image-field input[type='file'] {
  display: none;
}

/* Add space between nav links */
.product-edit-container {
  display: flex;
}
.product-edit-container .tab-content{
  width: 100%;
}
/* Add space between nav links */
.CNav {
  margin-bottom: 1rem;
}

/* Style for inactive nav links */
.CNavLink.nav-link-custom {
  color: #333;
  padding: 0.5rem;
  transition: background-color 0.3s;
}

/* Style for active nav link */
.CNavLink.active {
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

ul.nav.nav-tabs.flex-column.me-3 {
  width: 30%;
  border: none;
}
// li.nav-item {
//   margin-bottom: 1rem;
// }
.nav-tabs .nav-link {
  border-bottom-left-radius: var(--cui-nav-tabs-border-radius);
  border-bottom-right-radius: var(--cui-nav-tabs-border-radius);
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  border-bottom-left-radius: var(--cui-nav-tabs-border-radius);
  border-bottom-right-radius: var(--cui-nav-tabs-border-radius);
  border-color: #c4c9d0;
  color: #fff;
  background-color: #007bff;
}
.checkbox-label {
  margin-left: 10px; /* Adjust the value to set the desired space between checkbox and label */
}
.forgot_btn{
  padding: 10px 20px
}


// common class //

.error-text{
  font-size: 14px;
  line-height: 15px;
  color: red !important;
  margin: 0 !important;
  line-height: normal;
}
.cursor-pointer{
  cursor: pointer;
}
.hover-table-bg:hover{
  background-color: #d8dbe0;
}
.green-underline{
  height: 2px;
  width: 100%;
  background-color:  #2eb85c;
}
.y-rey-underline{
  width: 1px;
  background-color: #2eb85c;
}
.category:hover{
  background-color: #2eb85c87;
}
.category-click{
  background-color: #2eb85c87;
  color: #000 !important;
}
.bg-blue{
 background-color: #009efb2e; 
}
.bg-fff{
  background-color: #ffffff;
}

.fw-300{
  font-weight: 300;
}

.w-100px{
  width: 100px;
}
.max-w-50{
  max-width: 50%;
}
.w-webkit{
  width: -webkit-fill-available;
}
.w-fit-Content{
  width: fit-content;
}
.w-max-Content{
  width: max-content;
}
.w-webkit{
  width: -webkit-fill-available;
}

.custom-date-input{
  border: none;
}

.search-table{
  position: absolute;
  top: 55px;
  left: -4px;
  z-index: 1;
}

.p-10-20{
  padding: 9px 19px;
}

.border-radius-4{
  border-radius: 4px !important;
}
//  style for xlsx file //@at-root

.xlsx-file{
  background-color: #009efb;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 4px !important;
}
.file-remove-icon{
  top: -5px;
  right: 2px;
  transform: translate(50%);
  border-radius: 50%;
  cursor: pointer;
  color: #000000;
}

.setup_sec {
  border-bottom: 1px solid rgb(238, 238, 238);
  padding-bottom: 10px;
  margin-bottom: 0px;
}
// font-size-- //

.fs-10px{
font-size: 10px;
}
.fs-12px{
  font-size: 12px;
}

// right //

.position-top-left{
  top: 8px;
  left: 6px;
}
.select-tag select{
  width: 50%;
}

.datetime-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 10px 14px;
  }
  
.datetime-picker .css-fy6cn6-MuiStack-root {
      min-width: 255px;
  }


  // Input type Nuber Side Arrow Removi scss //

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.product-image-show{
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.icon-image-delete{
  position: absolute;
}

.py-4px{
  padding: 4px 0px !important;
}

/* styles.css (or your preferred CSS file) */
.infinite-scroll-component::-webkit-scrollbar {
  width: 0 !important; /* Hide horizontal scrollbar for WebKit browsers (e.g., Chrome, Safari) */
}

.infinite-scroll-component {
  scrollbar-width: none; /* Hide horizontal scrollbar for Firefox */
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background: transparent; /* Hide the scrollbar thumb for WebKit browsers */
}

.infinite-scroll-component::-webkit-scrollbar-track {
  background: transparent; /* Hide the scrollbar track for WebKit browsers */
} 

.wid .modal-dialog{
  max-width: 92vw !important;
  width: 92vw !important;
}
